import React from 'react';
import {
  ChakraProvider,
  Box,
  extendTheme,
} from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom'
import { CRAPage } from './pages/cra';
import { LandingPage } from './pages/landing';


const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        backgroundImage: "background_image_green_tall.jpg",
        backgroundSize: "cover",
        backgroundPosition: "top"
      }
    })
  }
 });

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Routes>
          <Route path="/cra" element={<CRAPage />}/>
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </Box>
    </ChakraProvider>
  );
}

export default App;
