import { Box, Center, Grid, GridItem, Image, Input, Stack, Text } from "@chakra-ui/react";
import { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { colors } from "../config/colors"

export function LandingPage (props){

  useLayoutEffect(() => {
    document.title = "Assistant GM"
  })

  return (
    <>
    <Box >
      <Center>
        <Grid
          h='calc(100vh)'
          w='calc(60vw)'
          templateAreas={`
          "header topnav"
          "maintop maintop"
          "mainleft mainright"
          "mainbottom mainbottom"
          "footer footer"`}
          gridTemplateRows={'50px 1fr 1fr 1fr 50px'}
          gridTemplateColumns={'1fr 1fr'}
          gap='0'
        >
          <GridItem area={"header"}  >
            <Stack direction={"horizontal"} minHeight={"100%"}>
              <Image src="logo.png" maxHeight={'48px'}></Image>
              <Text color={colors.LOGOWHITE} fontWeight={1000} fontSize={'30px'} >Assistant GM</Text>
            </Stack>
          </GridItem>

          <GridItem area={'topnav'} >
            <Box display={'flex'} justifyContent="flex-end" paddingRight={'10px'}>
              <Stack direction={"horizontal"} minHeight={"100%"} spacing={5}>
                  <Link style={{color: colors.WHITE, fontWeight: 600, fontSize: '18px'}} href="#" >LEAGUE</Link>
                  <Link style={{color: colors.WHITE, fontWeight: 600, fontSize: '18px'}} href="#">TEAMS</Link>
                  <Link style={{color: colors.WHITE, fontWeight: 600, fontSize: '18px'}} href="#">PLAYERS</Link>
                  <Link style={{color: colors.WHITE, fontWeight: 600, fontSize: '18px'}} href="#">ABOUT</Link>
                </Stack>
            </Box>
          </GridItem>

          <GridItem area={"maintop"}>
            <Stack>
              <Text color={colors.WHITE}>
              NHL salary cap analysis and player performance tracking
              </Text>
              <Center>
              <Input color={colors.WHITE} w="25%" placeholder="Search for a player or team"></Input>
              </Center>
            </Stack>

          </GridItem>

          <GridItem area={"mainleft"}>
          <Stack>
              <Text color={colors.TEAL}>
              LEAGUE VIEW
              </Text>

              <Text color={colors.TEAL}>
              PLAYER PERFORMANCE ANALYTICS
              </Text>

              <Text color={colors.TEAL}>
              Beyond advanced stats. Complete your player performance analysis with our analytics.
              </Text>
            </Stack>
          </GridItem>

          <GridItem area={"mainright"}>
          <Stack>
              <Text color={colors.TEAL}>
              PLAYER VIEW
              </Text>

              <Text color={colors.TEAL}>
              PLAYER CAP VALUE ANALYTICS
              </Text>

              <Text color={colors.TEAL}>
              Discover which players provide the best value based on their performance vs salary cap hit.
              </Text>
            </Stack>
          </GridItem>

          <GridItem area={"mainbottom"}>
            <Stack>
              <Text color={colors.TEAL}>
                RECENT PLAYER TRANSACTIONS
              </Text>
              <Text color={colors.TEAL}>
                LATEST NHL NEWS
              </Text>
            </Stack>
          </GridItem>

          <GridItem area={"footer"} w="100%">
            <Text backgroundColor='#222222' color={colors.WHITE}>
            © 2024 Assistant GM. All rights reserved. 
            </Text>
          </GridItem>
        </Grid>
      </Center>
      
    </Box>
    
    </>
  )
}